/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable consistent-return */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Spinner,
  Form,
  Tabs,
  Table,
  Tab,
  InputGroup,
  FormControl
} from 'react-bootstrap';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import '../assets/coverage.css';
import Select from 'react-select';
import CustomContentProgressbar from '../components/CoverageProgressbar';
import CoverageGridView from '../components/CoverageGridView';
import Layout from '../components/layout';
import TabsTitle from '../components/tabsTitle/tabsTitle';
import { getLast, getAll, findOne } from '../services/test';

class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gs: {
        lines: { total: 0, covered: 0, skipped: 0, pct: 0 },
        statements: { total: 0, covered: 0, skipped: 0, pct: 0 },
        functions: { total: 0, covered: 0, skipped: 0, pct: 0 },
        branches: { total: 0, covered: 0, skipped: 0, pct: 0 }
      },
      pg: 0,
      data: [],
      listOfTest: [],
      filterTypes: [],
      filterOptions: {
        byName: '',
        byTypes: []
      },
      fileLoad: false,
      isloading: true,
      isloadingListTest: true,
      whatTestIsLoadingOnDash: '',
      TestIDOnDash: '',
      showFiltersModal: 'ishidden',
      git: { branch: '', tag: '', author: '', commitMessage: '' }
    };
  }

  componentDidMount() {
    document.addEventListener('keydown', zEvent => {
      if (zEvent.ctrlKey && zEvent.key === 'f') this.handleShowFiltersModal();
    });
    getLast('airmodules').then(test => {
      if (test.coverageSummary)
        this.formatData(
          test.coverageSummary,
          test.jestResults,
          test.timestamp,
          test._id,
          test.branch,
          test.tag,
          test.author,
          test.commitMessage
        );
    });
    this.loadTestList({ cache: true });
  }

  addFilterOptions = (option, evt) => {
    if (!evt.target.checked) {
      this.setState(state => {
        const list = state.filterOptions.byTypes.filter(item => item !== option);

        return {
          filterOptions: {
            byTypes: list
          }
        };
      });
    } else {
      this.setState(state => {
        const list = [...state.filterOptions.byTypes, option];

        return {
          filterOptions: {
            byName: state.filterOptions.value,
            byTypes: list
          }
        };
      });
    }
  };

  clearFilterData = () => {
    this.setState({ data: this.dataOriginal });
  };

  handleSort = sort => {
    const { data } = this.state;
    let result = [...data];
    switch (sort.value) {
      case 'pctAsc':
        result = result.sort((a, b) => {
          let comparison = 0;
          if (parseInt(a.percentage.value, 10) > parseInt(b.percentage.value, 10)) {
            comparison = 1;
          } else if (parseInt(a.percentage.value, 10) < parseInt(b.percentage.value, 10)) {
            comparison = -1;
          }
          return comparison;
        });
        break;
      case 'pctDesc':
        result = result.sort((a, b) => {
          let comparison = 0;
          if (parseInt(a.percentage.value, 10) > parseInt(b.percentage.value, 10)) {
            comparison = 1;
          } else if (parseInt(a.percentage.value, 10) < parseInt(b.percentage.value, 10)) {
            comparison = -1;
          }
          return comparison * -1;
        });
        break;
      default:
        result = result.sort((a, b) => {
          let comparison = 0;
          if (parseInt(a.percentage.value, 10) > parseInt(b.percentage.value, 10)) {
            comparison = 1;
          } else if (parseInt(a.percentage.value, 10) < parseInt(b.percentage.value, 10)) {
            comparison = -1;
          }
          return comparison;
        });
        break;
    }
    this.setState({ data: result });
  };

  loadTestList = () => {
    this.setState({ isloadingListTest: true }, () =>
      getAll('airmodules')
        .then(listOfTest => {
          this.setState({
            isloadingListTest: false,
            listOfTest
          });
        })
        .catch(() => {
          this.setState({
            isloadingListTest: false
          });
        })
    );
    return true;
  };

  loadTestOnDash = test => {
    this.setState({ whatTestIsLoadingOnDash: test._id }, () => {
      findOne(test.id).then(response => {
        this.setState({ whatTestIsLoadingOnDash: null });
        this.formatData(
          response.coverageSummary,
          response.jestResults,
          response.timestamp,
          response._id
        );
      });
    });
  };

  handleCloseFiltersModal = () => {
    this.setState({ showFiltersModal: 'ishidden' });
  };

  handleShowFiltersModal = () => {
    const { showFiltersModal } = this.state;
    const state = showFiltersModal === 'ishidden' ? 'isvisible' : 'ishidden';
    this.setState({ showFiltersModal: state });
  };

  handleFilter(value) {
    let datos = [...this.dataOriginal];
    const { filterOptions } = this.state;
    if (filterOptions.byTypes.length > 0) {
      datos = datos.filter(test => {
        if (filterOptions.byTypes.includes(test.type)) return test;
        return null;
      });
    }

    datos = datos.filter(test => test.name.toLowerCase().includes(value.toLowerCase()));
    this.setState({
      data: datos,
      filterOptions: {
        byName: value,
        byTypes: filterOptions.byTypes
      }
    });
  }

  formatData(data, dataAdvance, timestamp, id, branch, tag, author, commitMessage) {
    let Temp = [];
    let p = 0;
    let gs = {};
    let pg = 0;

    const { commits } = data;
    delete data.commits;
    Object.keys(data).map(key => {
      const pivot = Object.assign(data[key]);
      p =
        Number(pivot.lines.pct) +
        Number(pivot.statements.pct) +
        Number(pivot.functions.pct) +
        Number(pivot.branches.pct);
      p = ((p / 400) * 100).toFixed(0);
      if (key === 'total') {
        pivot.name = key;
        pg = p;
        gs = pivot;
      } else {
        let dd = key.split('/');
        pivot.type = dd[dd.indexOf('components') + 1];
        dd = dd[dd.length - 1].split('.');
        // eslint-disable-next-line prefer-destructuring
        pivot.name = dd[0];
        pivot.testResults = [];
        pivot.numPassingTests = 0;
        // pivot.commits = data.commits.filter(commit => commit.files.includes(pivot.name));
        if (dataAdvance) {
          gs.numPassedTestSuites = dataAdvance.numPassedTestSuites;
          gs.numPassedTests = dataAdvance.numPassedTests;
          const { testResults } = dataAdvance;
          testResults.forEach(test => {
            if (test.testFilePath.includes(pivot.name)) {
              pivot.testResults = test.testResults;
              pivot.numPassingTests = test.numPassingTests;
            }
          });
        }
      }
      pivot.percentage = { value: p };

      Temp.push(pivot);
      return true;
    });
    Temp = Temp.sort((a, b) => {
      let comparison = 0;
      if (parseInt(a.percentage.value, 10) > parseInt(b.percentage.value, 10)) {
        comparison = -1;
      } else if (parseInt(a.percentage.value, 10) < parseInt(b.percentage.value, 10)) {
        comparison = 1;
      }
      return comparison;
    });
    this.dataOriginal = Temp;
    const types = Temp.map(item => item.type).filter(
      (value, index, self) => self.indexOf(value) === index
    );
    this.setState({
      gs,
      pg,
      data: Temp,
      fileLoad: true,
      timestamp,
      isloading: false,
      whatTestIsLoadingOnDash: '',
      TestIDOnDash: id,
      filterTypes: types,
      commits,
      git: { branch, tag, author, commitMessage }
    });
  }

  render() {
    const { listOfTest, commits } = this.state;
    const SortingOptions = [
      { label: 'Pct Desc', value: 'pctDesc' },
      { label: 'Pct Asc', value: 'pctAsc' }
    ];
    dayjs.extend(relativeTime);
    const coverage = { warning: 79, danger: 50 };
    return (
      <Layout>
        <Container fluid>
          <Row>
            <Col md={2}>
              <Card
                bg="dark"
                text="white"
                style={{
                  width: '100%',
                  minHeight: '89vh',
                  marginTop: '15px'
                }}
              >
                <Card.Body className="dashSidebar">
                  {this.state.isloading ? (
                    <Row>
                      <Col md={10}>
                        <Card.Title>Coverage</Card.Title>
                      </Col>
                      <Col md={2}>
                        <Spinner animation="border" variant="light" size="sm" />
                      </Col>
                    </Row>
                  ) : (
                    <Row noGutters>
                      <Col>
                        <div className="totalAnalicedContainer">
                          <div className="d-flex flex-column">
                            <span className="totalAnalicedTotalSuits">
                              {this.state.gs.numPassedTestSuites}
                            </span>

                            <span className="totalAnalicedTotalPassed">
                              {this.state.gs.numPassedTests}
                            </span>
                          </div>
                          <span className="vl">|</span>
                        </div>
                      </Col>
                      <Col>
                        <div className="totalAnalicedContainerText">
                          <span className="totalAnalicedPrimary">Test Suites</span>
                          <span className="totalAnaliced">Passed Tests</span>
                        </div>
                      </Col>
                    </Row>
                  )}
                  {this.state.fileLoad && !this.state.isloading && (
                    <>
                      <Row>
                        <Col>
                          <CustomContentProgressbar
                            percentage={this.state.pg}
                            initialAnimation
                            styles={{
                              background: {
                                fill: '#3e98c7'
                              },
                              text: {
                                fill:
                                  this.state.pg > coverage.warning
                                    ? '#28a745'
                                    : this.state.pg > coverage.danger
                                    ? '#ffc107'
                                    : '#dc3545',
                                fontSize: '24px',
                                fontWeight: 'bold'
                              },
                              path: {
                                stroke:
                                  this.state.pg > coverage.warning
                                    ? '#28a745'
                                    : this.state.pg > coverage.danger
                                    ? '#ffc107'
                                    : '#dc3545'
                              },
                              trail: { stroke: '#fff' }
                            }}
                          >
                            <span
                              style={{
                                fontSize: '64px',
                                fontWeight: 'bold',
                                color: `${
                                  this.state.pg > coverage.warning
                                    ? '#28a745'
                                    : this.state.pg > coverage.danger
                                    ? '#ffc107'
                                    : '#dc3545'
                                }`
                              }}
                            >
                              {`${this.state.pg}%`}
                            </span>
                          </CustomContentProgressbar>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <CustomContentProgressbar
                            percentage={this.state.gs.lines.pct}
                            initialAnimation
                            styles={{
                              background: {
                                fill: '#3e98c7'
                              },
                              text: {
                                fill:
                                  this.state.gs.lines.pct > coverage.warning
                                    ? '#28a745'
                                    : this.state.gs.lines.pct > coverage.danger
                                    ? '#ffc107'
                                    : '#dc3545',
                                fontSize: '24px',
                                fontWeight: 'bold'
                              },
                              path: {
                                stroke:
                                  this.state.gs.lines.pct > coverage.warning
                                    ? '#28a745'
                                    : this.state.gs.lines.pct > coverage.danger
                                    ? '#ffc107'
                                    : '#dc3545'
                              },
                              trail: { stroke: '#fff' }
                            }}
                          >
                            <span
                              style={{
                                fontSize: '24px',
                                fontWeight: 'bold',
                                color: `${
                                  this.state.gs.lines.pct > coverage.warning
                                    ? '#28a745'
                                    : this.state.gs.lines.pct > coverage.danger
                                    ? '#ffc107'
                                    : '#dc3545'
                                }`
                              }}
                            >
                              {`${Math.trunc(this.state.gs.lines.pct)}%`}
                            </span>
                          </CustomContentProgressbar>
                          <span
                            style={{
                              fontSize: '14px',
                              fontWeight: 'bold',
                              color: `${
                                this.state.gs.lines.pct > coverage.warning
                                  ? '#28a745'
                                  : this.state.gs.lines.pct > coverage.danger
                                  ? '#ffc107'
                                  : '#dc3545'
                              }`
                            }}
                          >
                            Lines
                          </span>
                        </Col>
                        <Col>
                          <CustomContentProgressbar
                            percentage={this.state.gs.statements.pct}
                            initialAnimation
                            styles={{
                              background: {
                                fill: '#3e98c7'
                              },
                              text: {
                                fill:
                                  this.state.gs.statements.pct > coverage.warning
                                    ? '#28a745'
                                    : this.state.gs.statements.pct > coverage.danger
                                    ? '#ffc107'
                                    : '#dc3545',
                                fontSize: '24px',
                                fontWeight: 'bold'
                              },
                              path: {
                                stroke:
                                  this.state.gs.statements.pct > coverage.warning
                                    ? '#28a745'
                                    : this.state.gs.statements.pct > coverage.danger
                                    ? '#ffc107'
                                    : '#dc3545'
                              },
                              trail: { stroke: '#fff' }
                            }}
                          >
                            <span
                              style={{
                                fontSize: '24px',
                                fontWeight: 'bold',
                                color: `${
                                  this.state.gs.statements.pct > coverage.warning
                                    ? '#28a745'
                                    : this.state.gs.statements.pct > coverage.danger
                                    ? '#ffc107'
                                    : '#dc3545'
                                }`
                              }}
                            >
                              {`${Math.trunc(this.state.gs.statements.pct)}%`}
                            </span>
                          </CustomContentProgressbar>
                          <span
                            style={{
                              fontSize: '14px',
                              fontWeight: 'bold',
                              color: `${
                                this.state.gs.statements.pct > coverage.warning
                                  ? '#28a745'
                                  : this.state.gs.statements.pct > coverage.danger
                                  ? '#ffc107'
                                  : '#dc3545'
                              }`
                            }}
                          >
                            Statements
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <CustomContentProgressbar
                            percentage={this.state.gs.functions.pct}
                            initialAnimation
                            styles={{
                              background: {
                                fill: '#3e98c7'
                              },
                              text: {
                                fill:
                                  this.state.gs.functions.pct > coverage.warning
                                    ? '#28a745'
                                    : this.state.gs.functions.pct > coverage.danger
                                    ? '#ffc107'
                                    : '#dc3545',
                                fontSize: '24px',
                                fontWeight: 'bold'
                              },
                              path: {
                                stroke:
                                  this.state.gs.functions.pct > coverage.warning
                                    ? '#28a745'
                                    : this.state.gs.functions.pct > coverage.danger
                                    ? '#ffc107'
                                    : '#dc3545'
                              },
                              trail: { stroke: '#fff' }
                            }}
                          >
                            <span
                              style={{
                                fontSize: '24px',
                                fontWeight: 'bold',
                                color: `${
                                  this.state.gs.functions.pct > coverage.warning
                                    ? '#28a745'
                                    : this.state.gs.functions.pct > coverage.danger
                                    ? '#ffc107'
                                    : '#dc3545'
                                }`
                              }}
                            >
                              {`${Math.trunc(this.state.gs.functions.pct)}%`}
                            </span>
                          </CustomContentProgressbar>
                          <span
                            style={{
                              fontSize: '14px',
                              fontWeight: 'bold',
                              color: `${
                                this.state.gs.functions.pct > coverage.warning
                                  ? '#28a745'
                                  : this.state.gs.functions.pct > coverage.danger
                                  ? '#ffc107'
                                  : '#dc3545'
                              }`
                            }}
                          >
                            Functions
                          </span>
                        </Col>
                        <Col>
                          <CustomContentProgressbar
                            percentage={this.state.gs.branches.pct}
                            initialAnimation
                            styles={{
                              background: {
                                fill: '#3e98c7'
                              },
                              text: {
                                fill:
                                  this.state.gs.branches.pct > coverage.warning
                                    ? '#28a745'
                                    : this.state.gs.branches.pct > coverage.danger
                                    ? '#ffc107'
                                    : '#dc3545',
                                fontSize: '24px',
                                fontWeight: 'bold'
                              },
                              path: {
                                stroke:
                                  this.state.gs.branches.pct > coverage.warning
                                    ? '#28a745'
                                    : this.state.gs.branches.pct > coverage.danger
                                    ? '#ffc107'
                                    : '#dc3545'
                              },
                              trail: { stroke: '#fff' }
                            }}
                          >
                            <span
                              style={{
                                fontSize: '24px',
                                fontWeight: 'bold',
                                color: `${
                                  this.state.gs.branches.pct > coverage.warning
                                    ? '#28a745'
                                    : this.state.gs.branches.pct > coverage.danger
                                    ? '#ffc107'
                                    : '#dc3545'
                                }`
                              }}
                            >
                              {`${Math.trunc(this.state.gs.branches.pct)}%`}
                            </span>
                          </CustomContentProgressbar>
                          <span
                            style={{
                              fontSize: '14px',
                              fontWeight: 'bold',
                              color: `${
                                this.state.gs.branches.pct > coverage.warning
                                  ? '#28a745'
                                  : this.state.gs.branches.pct > coverage.danger
                                  ? '#ffc107'
                                  : '#dc3545'
                              }`
                            }}
                          >
                            Branches
                          </span>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: 'auto' }}>
                        <Col>
                          <div className="LastUpdate">
                            <hr style={{ width: '99%', borderColor: 'white' }} />
                            <p>
                              <b>Author: </b>
                              <span>{this.state.git.author}</span>
                            </p>
                            <p>
                              <b>Branch: </b>
                              <span>{this.state.git.branch}</span>
                            </p>
                            <p>
                              <b>Commit: </b>
                              <span>{this.state.git.commitMessage}</span>
                            </p>
                            <p>
                              <b>TestId: </b>
                              <span>{this.state.TestIDOnDash}</span>
                            </p>
                            <hr style={{ width: '99%', borderColor: 'white' }} />
                            <h6>{`Last Update: ${dayjs().to(this.state.timestamp)}`}</h6>
                          </div>
                        </Col>
                      </Row>
                    </>
                  )}
                </Card.Body>
              </Card>
            </Col>
            <Col md={10} className="pt-3">
              {this.state.fileLoad && !this.state.isloading ? (
                <Tabs defaultActiveKey="dash" id="uncontrolled-tab-example">
                  <Tab
                    eventKey="dash"
                    title={<TabsTitle text="Test Details" value={this.state.data.length} />}
                  >
                    <CoverageGridView data={this.state.data} />
                  </Tab>
                  <Tab
                    eventKey="testlist"
                    title={<TabsTitle text="Test List" value={listOfTest.length} />}
                  >
                    <Container fluid className="pt-5">
                      <Row className="mb-2">
                        <Col md={{ span: 4, offset: 8 }}>
                          <Button
                            className="mr-1"
                            variant="success"
                            onClick={() => this.loadTestList({ cache: true })}
                          >
                            {this.state.isloadingListTest && (
                              <Spinner animation="border" variant="light" size="sm" />
                            )}
                            Refresh list (Using Cache)!
                          </Button>
                          <Button
                            variant="danger"
                            onClick={() => this.loadTestList({ cache: false })}
                          >
                            {this.state.isloadingListTest && (
                              <Spinner animation="border" variant="light" size="sm" />
                            )}
                            Refresh list (No use cache)!
                          </Button>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Table striped bordered hover variant="dark" responsive>
                            <thead>
                              <tr>
                                <th># ID</th>
                                <th>Date</th>
                                <th>Branch</th>
                                <th>Book</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            {listOfTest.length < 1 && this.state.isloadingListTest ? (
                              <tbody>
                                <tr>
                                  <td colSpan={4}>
                                    <Spinner animation="border" variant="light" size="sm" />
                                  </td>
                                </tr>
                              </tbody>
                            ) : (
                              <tbody>
                                {listOfTest.map(test => {
                                  return (
                                    <tr key={test._id}>
                                      <td>{test._id}</td>
                                      <td>
                                        {`${dayjs(test.timestamp).toString()}   ( 
                                        ${dayjs().to(test.timestamp)})`}
                                      </td>
                                      <td>{test.branch}</td>
                                      <td>{test.book[0]}</td>
                                      <td>
                                        {this.state.TestIDOnDash !== test._id && (
                                          <Button
                                            variant="outline-light"
                                            onClick={() => this.loadTestOnDash(test)}
                                          >
                                            {this.state.whatTestIsLoadingOnDash === test._id && (
                                              <Spinner
                                                animation="border"
                                                variant="info"
                                                size="sm"
                                              />
                                            )}
                                            Load on Dash
                                          </Button>
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            )}
                          </Table>
                        </Col>
                      </Row>
                    </Container>
                  </Tab>
                  <Tab eventKey="commits" title={<TabsTitle text="Commits" value={commits.length} />}>
                    <Container fluid className="mt-4">
                      <Row>
                        <Col>
                          <Table striped bordered hover variant="dark" responsive size="sm">
                            <thead>
                              <tr>
                                <th>Date</th>
                                <th>Author</th>
                                <th>Subject</th>
                                <th>Body</th>
                              </tr>
                            </thead>
                            {commits.length < 1 && this.state.isloadingListTest ? (
                              <tbody>
                                <tr>
                                  <td colSpan={4}>
                                    <Spinner animation="border" variant="light" size="sm" />
                                  </td>
                                </tr>
                              </tbody>
                            ) : (
                              <tbody>
                                {commits.map(commit => {
                                  return (
                                    <tr key={commit.hash}>
                                      <td>
                                        {`${dayjs(commit.authorDate).toString()}   ( 
                                          ${dayjs().to(commit.authorDate)})`}
                                      </td>
                                      <td>{`${commit.authorName}-${commit.authorEmail}`}</td>
                                      <td>{commit.subject}</td>
                                      <td>{commit.body}</td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            )}
                          </Table>
                        </Col>
                      </Row>
                    </Container>
                  </Tab>
                </Tabs>
              ) : (
                <Row>
                  <Col md={10} className="m-5">
                    <Spinner animation="grow" variant="dark" />
                    <Spinner animation="grow" variant="secondary" />
                    <Spinner animation="grow" variant="success" />
                    <Spinner animation="grow" variant="danger" />
                    <Spinner animation="grow" variant="warning" />
                    <Spinner animation="grow" variant="dark" />
                    <Spinner animation="grow" variant="success" />
                    <Spinner animation="grow" variant="dark" />
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
          <Button
            variant="dark"
            onClick={this.handleShowFiltersModal}
            className="FiltersModalPanelButton"
          >
            <i className="fa fa-filter" />
          </Button>
          <div className={`wrapper ${this.state.showFiltersModal}`}>
            <Card
              bg="light"
              text="dark"
              style={{
                minHeight: '80vh',
                width: '100%'
              }}
            >
              <Card.Body className="FiltersModalBody">
                <Container fluid className="d-flex flex-column">
                  <Row>
                    <Col className="d-flex flex-row align-items-start">
                      <i className="fa fa-cogs mr-2" style={{ fontSize: '1.9rem' }} />
                      <h1>Advanced Filters</h1>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <hr style={{ width: '100%', color: 'black' }} />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <InputGroup>
                        <InputGroup.Prepend>
                          <Button variant="dark">
                            <i className="fa fa-search" />
                          </Button>
                        </InputGroup.Prepend>
                        <FormControl
                          placeholder="Search"
                          aria-label="Search"
                          aria-describedby="search"
                          onKeyDown={value => this.handleFilter(value.target.value)}
                        />
                      </InputGroup>
                    </Col>
                    <Col>
                      <Select
                        options={SortingOptions}
                        className="NavSortSelect"
                        placeholder="Sort"
                        onChange={value => this.handleSort(value)}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <hr style={{ width: '100%', color: 'black' }} />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group id="formGridCheckbox">
                        {this.state.filterTypes.map(filter => {
                          if (filter)
                            return (
                              <Form.Check
                                type="checkbox"
                                label={filter}
                                aria-label={filter}
                                id={filter}
                                key={filter}
                                checked={this.state.filterOptions.byTypes.includes(filter)}
                                custom
                                onChange={evt => this.addFilterOptions(filter, evt)}
                              />
                            );
                          return null;
                        })}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <hr style={{ width: '100%', color: 'black' }} />
                    </Col>
                  </Row>
                  <Row className="d-flex mt-auto">
                    <Col>
                      <Button variant="secondary" onClick={this.handleCloseFiltersModal}>
                        Close
                      </Button>
                      <Button variant="primary" onClick={() => this.handleFilter('', true)}>
                        Save Changes
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </Card.Body>
            </Card>
          </div>
        </Container>
      </Layout>
    );
  }
}

export default IndexPage;
