import * as logger from 'loglevel';
import axios from './axios';
import ENV from './__env';
import { getUser } from './auth';

const validateLastTemeResponse = response => {
  const validation = { isValid: true, message: '' };
  if (!response.success) {
    validation.isValid = false;
    return {
      isValid: false,
      data: response,
      message: `INTEGRATION__TESTS: ${response.error}`
    };
  }
  return validation;
};

// eslint-disable-next-line import/prefer-default-export
export const getLast = book =>
  new Promise((resolve, reject) => {
    if (!book) {
      const MESSAGE = 'INTEGRATION__TESTS: **book** is not especified.';
      logger.error(MESSAGE);
      reject(MESSAGE);
    }
    if (!ENV || !ENV.last || !ENV.last.method || !ENV.last.endPoint) {
      const MESSAGE = 'INTEGRATION__TESTS: **ENV.theme** configuration issues.';
      logger.error(MESSAGE);
      reject(MESSAGE);
    }
    axios({
      method: ENV.last.method,
      url: `${ENV.last.endPoint}/${book}`
    })
      .then(response => {
        const validation = validateLastTemeResponse(response.data);
        if (validation.isValid) {
          logger.info('INTEGRATION__TESTS: Successful request', response.data.success);
          resolve(response.data.success);
        } else {
          logger.error(validation.message, validation.data);
          reject(response.data);
        }
      })
      .catch(err => {
        logger.error('INTEGRATION__TESTS:', err);
        reject(err);
      });
  });

const validateAllTemeResponse = response => {
  const validation = { isValid: true, message: '' };
  if (!response.success) {
    validation.isValid = false;
    return {
      isValid: false,
      data: response,
      message: `INTEGRATION__TESTS__ALL: ${response.error}`
    };
  }
  return validation;
};

export const getAll = book =>
  new Promise((resolve, reject) => {
    if (!book) {
      const MESSAGE = 'INTEGRATION__TESTS__ALL: **book** is not especified.';
      logger.error(MESSAGE);
      reject(MESSAGE);
    }
    if (!ENV || !ENV.all || !ENV.all.method || !ENV.all.endPoint) {
      const MESSAGE = 'INTEGRATION__TESTS__ALL: **ENV.theme** configuration issues.';
      logger.error(MESSAGE);
      reject(MESSAGE);
    }
    axios({
      method: ENV.all.method,
      url: `${ENV.all.endPoint}/${book}`
    })
      .then(response => {
        const validation = validateAllTemeResponse(response.data);
        if (validation.isValid) {
          logger.info('INTEGRATION__TESTS__ALL: Successful request', response.data.success);
          resolve(response.data.success);
        } else {
          logger.error(validation.message, validation.data);
          reject(response.data);
        }
      })
      .catch(err => {
        logger.error('INTEGRATION__TESTS__ALL:', err);
        reject(err);
      });
  });

const validateFindOneResponse = response => {
  const validation = { isValid: true, message: '' };
  if (!response.id) {
    validation.isValid = false;
    return {
      isValid: false,
      data: response,
      message: `INTEGRATION__TESTS__FINDONE: ${response.error}`
    };
  }
  return validation;
};

export const findOne = id =>
  new Promise((resolve, reject) => {
    if (!id) {
      const MESSAGE = 'INTEGRATION__TESTS__FINDONE: **id** is not especified.';
      logger.error(MESSAGE);
      reject(MESSAGE);
    }
    if (!ENV || !ENV.findOne || !ENV.findOne.method || !ENV.findOne.endPoint) {
      const MESSAGE = 'INTEGRATION__TESTS__FINDONE: **ENV.findOne** configuration issues.';
      logger.error(MESSAGE);
      reject(MESSAGE);
    }
    axios({
      method: ENV.findOne.method,
      url: `${ENV.findOne.endPoint}/${id}`,
      headers: {
        Authorization: `Bearer ${getUser()}`
      }
    })
      .then(response => {
        const validation = validateFindOneResponse(response.data);
        if (validation.isValid) {
          logger.info('INTEGRATION__TESTS__FINDONE: Successful request', response.data);
          resolve(response.data);
        } else {
          logger.error(validation.message, validation.data);
          reject(response.data);
        }
      })
      .catch(err => {
        logger.error('INTEGRATION__TESTS__FINDONE:', err);
        reject(err);
      });
  });
