import React from 'react';
import { Badge } from 'react-bootstrap';
import PropTypes from 'prop-types';

const tabsTitle = props => {
  const { text, value } = props;
  return (
    <h6>
      {`${text} `}
      {value && <Badge variant="info">{value}</Badge>}
    </h6>
  );
};

tabsTitle.propTypes = {
  text: PropTypes.string,
  value: PropTypes.number
};

tabsTitle.defaultProps = {
  text: 'Example',
  value: 10
};
export default tabsTitle;
