/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Card, ProgressBar, Accordion } from 'react-bootstrap';
import shortid from 'shortid';
import Prism from 'prismjs';
import '../../assets/primsjs.css';

const coverage = { warning: 79, danger: 50 };
function renderCard(data) {
  const dat = data.filter(item => {
    if (item.name !== 'total') return item;
    return null;
  });
  return dat.map(item => {
    return (
      <Col key={shortid.generate()}>
        <Card
          bg="dark"
          text="white"
          key={shortid.generate()}
          className="Carditem"
          style={{
            width: '100%',
            minWidth: '490px',
            height: 'auto',
            minHeight: '450px',
            marginBottom: '3%',
            overflow: 'auto'
          }}
        >
          <Card.Body>
            <Container>
              <Row>
                <Col>
                  <div className="titleBox">
                    <span className="mainTitle">{item.name}</span>
                    <span className="elementType">{item.type.toUpperCase()}</span>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <ProgressBar
                    animated
                    variant={`${
                      item.percentage.value > coverage.warning
                        ? 'success'
                        : item.percentage.value > coverage.danger
                        ? 'warning'
                        : 'danger'
                    }`}
                    now={item.percentage.value}
                    label={`${item.percentage.value}%`}
                  />
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  <div className="cardItemStats">
                    <div>
                      <span className="titles">Lines</span>
                      <p>
                        <span>{item.lines.total}</span>- <span>{item.lines.covered}</span>-{' '}
                        <span>{item.lines.skipped}</span>-s{' '}
                        <span>{`${item.lines.pct.toFixed(0)}%`}</span>
                      </p>
                    </div>
                    <div>
                      <span className="titles">Statements</span>
                      <p>
                        <span>{item.statements.total}</span>- <span>{item.statements.covered}</span>
                        - - <span>{item.statements.skipped}</span>-{' '}
                        <span>{`${item.statements.pct.toFixed(0)}%`}</span>
                      </p>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="cardItemStats">
                    <div>
                      <span className="titles">Functions</span>
                      <p>
                        <span>{item.functions.total}</span>- <span>{item.functions.covered}</span>-{' '}
                        <span>{item.functions.skipped}</span>-{' '}
                        <span>{`${item.functions.pct.toFixed(0)}%`}</span>
                      </p>
                    </div>
                    <div>
                      <span className="titles">Branches</span>
                      <p>
                        <span>{item.branches.total}</span>- <span>{item.branches.covered}</span>-{' '}
                        <span>{item.branches.skipped}</span>-{' '}
                        <span>{`${item.branches.pct.toFixed(0)}%`}</span>
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="cardItemTest">
                    <div>
                      <span className="titles">Test UC</span>
                      <Accordion defaultActiveKey="0">
                        {item.testResults.map((test, index) => {
                          return (
                            <Card
                              bg={`${test.status === 'passed' ? 'success' : 'danger'}`}
                              text="white"
                              key={`Card-${shortid.generate()}`}
                            >
                              <Accordion.Toggle
                                as={Card.Header}
                                eventKey={index}
                                className="text-light"
                                key={`UTC-${shortid.generate()}`}
                              >
                                <div className="cardItemListItem">
                                  <i
                                    className={`text-light fa ${
                                      test.status === 'passed' ? 'fa-check' : 'fa-times'
                                    } test-${test.status} fr-pr-1`}
                                  />
                                  <span
                                    className={`text-truncate ${
                                      test.status === 'passed' ? '' : 'test-failed'
                                    }`}
                                    style={{
                                      display: 'inline-block',
                                      minWidth: '250px'
                                    }}
                                  >
                                    {test.title}
                                  </span>
                                  <span
                                    className={`ml-auto ${
                                      test.status === 'passed' ? '' : 'test-failed'
                                    }`}
                                  >
                                    {test.duration}
                                    ms
                                  </span>
                                </div>
                              </Accordion.Toggle>
                              <Accordion.Collapse eventKey={index}>
                                <Card.Body>
                                  <pre className="text-light">
                                    <code>{test.filureMessages}</code>
                                  </pre>
                                </Card.Body>
                              </Accordion.Collapse>
                            </Card>
                          );
                        })}
                      </Accordion>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </Card.Body>
        </Card>
      </Col>
    );
  });
}

function CoverageGridView(props) {
  const { data } = props;
  useEffect(() => {
    Prism.highlightAll();
  }, []);
  return (
    <Container
      fluid
      style={{
        width: '99%',
        minHeight: '90vh',
        marginTop: '15px',
        marginLeft: '15px'
      }}
    >
      <Row>{renderCard(data)}</Row>
    </Container>
  );
}

CoverageGridView.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.array
};
CoverageGridView.defaultProps = {
  data: []
};
export default CoverageGridView;
